html,
body {
  height: 100%;
  margin: 0;
  background-color: white;
}

#root {
  height: 100%;
}

.App {
  display: flex;
  flex-flow: column;
  height: 100%;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-base {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
}

.app-size {
  width: 992px;
  height: 100%;
}

/* --------------------------------- Common --------------------------------- */

.black {
  color: #000000d9 !important;
}

.white {
  color: white !important;
}

.m-l {
  margin-left: 50px;
}

.m-r {
  margin-right: 50px;
}

.flex-1 {
  flex: 1;
}

/* ------------------------------- Navigation ------------------------------- */

.header-bar {
  position: relative;
  z-index: 100;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  color: white;
  font-size: x-large;
  font-family: 'Arial';
  font-weight: 700;
  /* width: 50%; */
}

.header-contact {
  border-radius: 15px !important;
  padding: 5px 16px !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  display: flex !important;
  align-items: center !important;
}

.slogon-box {
  position: absolute;
  top: 200px;
  width: 460px;
  height: 300px;
  /* border: white 1px dashed; */
}

.slogan-large-title {
  color: white;
  font-size: 45px;
  font-family: 'Lato', sans-serif;
  line-height: normal;
  margin-bottom: 20px;
}

.slogan-small-title {
  width: 85%;
  color: white;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  text-align: justify;
}

.nav-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background-color: white;
}

.icons-outer-box {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;
}

.icon-box {
  width: 180px;
  height: 550px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.nav-btn-box {
  width: 160px;
  height: 160px;
}

.nav-btn {
  width: 160px;
  height: 160px;
  background-color: white;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -160px;
  transition: all 0.2s ease;
}

.nav-btn:hover {
  opacity: 0;
}

.nav-btn-bg {
  width: 160px;
  height: 160px;
  background: linear-gradient(-45deg, #47bffc, #7591fc);
  border-radius: 7px;
  box-shadow: 5px 5px 20px #039ae54f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}

.nav-btn-icon {
  width: 45%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-btn-title {
  font-family: 'Arial';
  font-weight: 800;
  font-size: 16px;
  text-align: center;
  line-height: 16px;
  color: rgb(50, 50, 50);
}

.landing-bg-dark-blue {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 600px;
  background-color: #3a47f6;
  border-bottom-left-radius: 120px;
  overflow: hidden;
}

.landing-circle {
  position: absolute;
  top: 20vh;
  right: -15vw;
  width: 1000px;
  height: 1000px;
  border-radius: 50%;
  background-color: #1687f1;
}

.landing-bg-mask {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  background-image: linear-gradient(
    transparent,
    transparent,
    transparent,
    rgba(0, 0, 0, 0.5),
    black
  );
}

.download-title {
  font-family: 'Lato', sans-serif;
  font-weight: 900;
  color: rgb(50, 50, 50);
  font-size: 35px;
}

.footer-2 {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 50px;
  margin-top: 40px;
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
}

.footer-left {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.apple {
  width: 180px;
  height: 60px;
  background-image: url('../src/assets/app-store-badge.png');
  background-size: auto 45px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.google {
  width: 300px;
  height: 60px;
  background-image: url('../src/assets/google-play-badge.png');
  background-size: auto 45px;
  background-repeat: no-repeat;
  cursor: pointer;
}

.disclaimer {
  display: flex;
  width: 660px;
}

/* ------------------------------- Components ------------------------------- */
.calculator-box {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 90px;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.97);
  border-radius: 7px;
  box-shadow: 2px 2px 10px -4px grey;
}

.calculator-inner-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.calculator-box-row {
  display: flex;
  width: 100%;
}

.calculator-half-box {
  width: 50%;
  padding: 0px 10px;
  /* border: 1px dashed grey; */
}

.calculator-full-box {
  width: 100%;
  padding: 0px 10px;
  /* border: 1px dashed grey; */
}

.shadow {
  box-shadow: 2px 2px 10px #ddd;
}

.inset-shadow {
  box-shadow: 1px 1px 5px #ddd inset;
}

.radius {
  border-radius: 7px !important;
}

.half-radius {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.white-bg {
  padding: 10px 20px 25px;
  background-color: white;
  width: 100%;
}

.blank-bg {
  padding: 10px 20px 25px;
  width: 100%;
}

.m-bottom {
  margin-bottom: 18px;
}

.row {
}

.divider {
  margin-bottom: 10px !important;
}

.tutube-input {
  margin-top: 10px;
}

.ant-select-selector {
  height: 39.8px !important;
  display: flex;
  align-items: center;
}

.tutube-output {
  margin-top: 10px;
  margin-bottom: -8.4px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.item-title {
  font-size: 20px;
}

.btn-group-box-lg {
  height: 50px;
}

.btn-group-box-sm {
  height: 35px;
  width: 55%;
}

.btn-group-lg {
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  top: -50px;
}

.btn-group-sm {
  background-color: transparent;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  position: relative;
  top: -35px;
}

.btn-group-2 {
  background: white;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.btn-group-btn {
  background: transparent;
  height: 100%;
  flex: 1;
  font-size: 20px;
  cursor: pointer;
  border: 0px;
  outline: 0px;
  color: #000000d9;
  transition: all 0.3s ease;
}

.btn-group-btn-sm {
  background: transparent;
  height: 100%;
  flex: 1;
  font-size: 16px;
  cursor: pointer;
  border: 0px;
  outline: 0px;
  color: #000000d9;
  transition: all 0.3s ease;
}

.btn-group-btn:hover {
  opacity: 0.5;
}

.btn-group-btn:focus {
  opacity: 1;
}

.btn-group-btn-bg {
  background: linear-gradient(-45deg, #47bffc, #7591fc);
  height: 100%;
  flex: 1;
  opacity: 0;
  transition: all 0.3s ease;
}

.btn-group-btn-bg-focus {
  opacity: 1;
}

.btn-group-row {
  display: flex;
  align-items: center;
}

.diff-results-box {
  display: flex;
  height: 150px;
  width: 100%;
  justify-content: space-between;
}

.single-result-box {
  height: 100%;
  width: 32%;
  border: 1px solid black;
}

.chart-box {
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
  color: rgb(140, 140, 140);
}

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 40px;
}

.chart-declaimer {
  width: 100%;
  display: flex;
}

.inner-chart-declaimer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.declaimer {
  margin-left: 55px;
  width: 260px;
}

.label-year {
  width: 50px;
  position: relative;
  left: 97%;
  bottom: 44.5px;
}

.legend-box {
  display: flex;
  width: 260px;
  margin-left: 285px;
  justify-content: space-around;
}

.legend {
  display: flex;
  align-items: center;
}

.legend-1 {
  width: 16px;
  height: 16px;
  background-color: #47bffc;
  border-radius: 4px;
  margin-right: 8px;
}

.legend-2 {
  width: 16px;
  height: 16px;
  background-color: #7591fc;
  border-radius: 4px;
  margin-right: 8px;
}

/* .ant-table-thead > tr > th {
  background: white !important;
} */

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #e1f5feb4 !important;
}

.calculator-nav-box {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  margin-top: 25px;
  z-index: 1;
}

.calculator-nav-inner-box {
  width: 1366px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.calculator-nav-title {
  color: white;
  font-size: x-large;
  font-family: 'Arial';
  font-weight: 900;
}

.calculator-nav-btn-box {
  height: 30px;
}

.calculator-nav-btn-bg {
  cursor: pointer;
  color: white;
  background: linear-gradient(-45deg, #47bffc, #7591fc);
  width: 110px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition: all 0.2s ease;
}

.calculator-nav-btn {
  cursor: pointer;
  color: #3a47f6;
  /* border: 1px solid #47bffc; */
  background-color: white;
  width: 110px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  transition: all 0.1s ease;
  position: relative;
  top: -30px;
  transition: all 0.2s ease;
}

.calculator-nav-btn:hover {
  opacity: 0;
}

/* ---------------------------- responsive layout --------------------------- */

@media screen and (max-width: 992px) {
  .app-size {
    width: 768px;
  }

  .legend-box {
    display: flex;
    width: 260px;
    margin-left: 100px;
    justify-content: space-around;
  }

  .landing-circle {
    top: 25vh;
    right: -35vw;
    width: 800px;
    height: 800px;
  }

  .icon-box {
    width: 160px;
    height: 500px;
  }

  .nav-btn {
    width: 145px;
    height: 145px;
    top: -145px;
  }

  .nav-btn-bg {
    width: 145px;
    height: 145px;
  }

  .nav-btn-title {
    font-family: 'Arial';
    font-weight: 700;
    font-size: 15px;
  }

  .slogan-large-title {
    font-size: 40px;
  }

  .slogan-small-title {
    font-size: 14px;
  }

  .m-r {
    margin-right: 0px;
  }

  .footer-left {
    width: 60%;
  }

  .btn-group-btn-sm {
    font-size: 14px;
  }

  .calculator-nav-title {
    font-size: 16px;
    font-weight: 700;
    color: white;
  }
  .calculator-nav-btn-bg {
    width: 80px;
    font-size: 12px;
    height: 26px;
  }
  .calculator-nav-btn {
    width: 80px;
    font-size: 12px;
    height: 26px;
    top: -26px;
  }
}
